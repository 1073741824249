import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-gantt/styles/material.css';
import '@syncfusion/ej2-grids/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-layouts/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';
import '@syncfusion/ej2-treegrid/styles/material.css';

import AddProjectRoleDialog from 'components/ProjectsGrid/AddProjectRoleDialog';
import { PersonnelListResult, ProjectListResultItem } from 'components/ProjectsGrid/ProjectsGrid';
import { TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX } from 'constants/themes/dimensions';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import { Person, Project } from 'types/generated/graphql';

import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  DayMarkers,
  Edit,
  Filter,
  GanttComponent,
  GanttModel,
  Inject,
  PdfExport,
  PdfExportProperties,
  Reorder,
  Resize,
  RowDD,
  Selection,
  Sort,
  Toolbar,
  VirtualScroll,
  ZoomTimelineSettings,
} from '@syncfusion/ej2-react-gantt';
import { CommandColumn, excelQueryCellInfo, GridComponent } from '@syncfusion/ej2-react-grids';

import AddProjectDialog from '../ProjectGantt/AddProjectDialog';
import MassProjectUploadDialog from '../ProjectGantt/MassProjectUploadDialog';
import { ANY_PROJECT } from '../ProjectGantt/ProjectGantt';

type GanttChartEmitTypeProperty =
  | 'queryTaskbarInfo'
  | 'beforeExcelExport'
  | 'excelExportComplete'
  | 'excelQueryCellInfo'
  | 'excelHeaderQueryCellInfo'
  | 'rowDrag'
  | 'rowDragStart'
  | 'rowDragStartHelper'
  | 'rowDrop'
  | 'collapsing'
  | 'collapsed'
  | 'expanding'
  | 'expanded'
  | 'actionBegin'
  | 'actionComplete'
  | 'actionFailure'
  | 'dataStateChange'
  | 'taskbarEdited'
  | 'endEdit'
  | 'cellEdit'
  | 'load'
  | 'created'
  | 'destroyed'
  | 'taskbarEditing'
  | 'dataBound'
  | 'resizeStart'
  | 'resizing'
  | 'resizeStop'
  | 'splitterResizeStart'
  | 'splitterResizing'
  | 'splitterResized'
  | 'columnDragStart'
  | 'columnDrag'
  | 'columnDrop'
  | 'beforeTooltipRender'
  | 'rowSelecting'
  | 'rowSelected'
  | 'rowDeselecting'
  | 'rowDeselected'
  | 'cellSelecting'
  | 'cellSelected'
  | 'cellDeselecting'
  | 'cellDeselected'
  | 'queryCellInfo'
  | 'headerCellInfo'
  | 'rowDataBound'
  | 'columnMenuOpen'
  | 'toolbarClick'
  | 'columnMenuClick'
  | 'contextMenuOpen'
  | 'contextMenuClick'
  | 'onTaskbarClick'
  | 'recordDoubleClick'
  | 'onMouseMove'
  | 'beforePdfExport'
  | 'pdfExportComplete'
  | 'pdfQueryCellInfo'
  | 'pdfQueryTaskbarInfo'
  | 'pdfQueryTimelineCellInfo'
  | 'pdfColumnHeaderQueryCellInfo'
  | 'disableToolbarItems';

type GanttChartProps = {
  ganttComponentProps?: React.ComponentProps<typeof GanttComponent>;
  columnDirectivePropsList: React.ComponentProps<typeof ColumnDirective>[];
  logEmitTypeProperties?: GanttChartEmitTypeProperty[];
  setGanttInstance?: (x: GanttComponent | null | undefined) => void;
  toolbar: any[];
  openAddRolesDialog?: boolean;
  setOpenAddRolesDialog?: (x: boolean) => void;
  personnelList?: PersonnelListResult[];
  projectList?: ProjectListResultItem[];
  setEditTaskbar?: (x: boolean) => void;
  selectedProject?: {
    id: string;
    name: string;
  };
  dataFilterProject?: string[] | null;
  onDataChanged?: { (): Promise<void> };
};

const ROW_HEIGHT = 24;
// The taskbar's rendering is overridden, however we still need to set it in order to keep the
// SyncFusion styling math from going completely haywire.
export const TASKBAR_HEIGHT = ROW_HEIGHT - 4;
const GANTT_CHART_HEIGHT = `calc(100vh - ${TOTAL_HEIGHT_OVERHEAD_FROM_APP_WRAPPING_PX + 95}px)`;

export const GanttChart: FC<GanttChartProps & { isProject?: boolean }> = ({
  ganttComponentProps = {},
  logEmitTypeProperties = [],
  columnDirectivePropsList,
  setGanttInstance,
  toolbar,
  isProject,
  openAddRolesDialog,
  setOpenAddRolesDialog,
  personnelList,
  projectList,
  setEditTaskbar,
  selectedProject,
  dataFilterProject,
  onDataChanged,
}) => {
  let ganttInstance: any = useRef<GanttComponent | null | undefined>(null);
  const [isAddProjectDialogOpen, setIsAddProjectDialogOpen] = useState(false);
  const [isMassProjectUploadDialogOpen, setIsMassProjectUploadDialogOpen] = useState(false);
  const [ganttChartInstance, setGanttChartInstance] = useState<GanttComponent>();
  const { isEnterpriseAdmin, isOnlyCraft } = useRoles();
  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;

  useEffect(() => {
    setGanttChartInstance(ganttInstance);
  }, []);

  useEffect(() => {
    if (setGanttInstance) {
      setGanttInstance(ganttInstance);
    }
  }, [ganttInstance, setGanttInstance]);

  const getGanttInstance = () => {
    return ganttChartInstance;
  };

  const scrollToToday = (ganttChartInstance: GanttComponent) => {
    var todayLeft = ganttChartInstance.dataOperation.getTaskLeft(DateTime.now().minus({ month: 1 }).toJSDate(), false);

    var scrollbar = document.getElementsByClassName('e-chart-scroll-container')[0] as HTMLElement;
    scrollbar.scrollLeft = todayLeft;
  };

  const customZoomingLevels: ZoomTimelineSettings[] = [
    {
      topTier: { unit: 'Year', format: 'yyyy', count: 1 },
      bottomTier: { unit: 'Month', format: 'MMM', count: 1 },
      timelineUnitSize: 66,
      level: 0,
      timelineViewMode: 'Year',
    },
    {
      topTier: { unit: 'Year', format: 'yyyy', count: 1 },
      bottomTier: { unit: 'Month', format: 'MMM', count: 1 },
      timelineUnitSize: 99,
      level: 1,
      timelineViewMode: 'Year',
    },
    {
      topTier: { unit: 'Year', format: 'yyyy', count: 1 },
      bottomTier: { unit: 'Month', format: 'MMM', count: 1 },
      timelineUnitSize: 132,
      level: 2,
      timelineViewMode: 'Year',
    },
    {
      topTier: { unit: 'Month', format: 'MMM, yy', count: 1 },
      bottomTier: {
        unit: 'None',
      },
      timelineUnitSize: 33,
      level: 3,
    },
    {
      topTier: { unit: 'Month', format: 'MMM, yy', count: 1 },
      bottomTier: {
        unit: 'None',
      },
      timelineUnitSize: 66,
      level: 4,
    },
    {
      topTier: { unit: 'Month', format: 'MMM, yy', count: 1 },
      bottomTier: {
        unit: 'None',
      },
      timelineUnitSize: 99,
      level: 5,
    },
  ];

  const dataBound: GanttModel['dataBound'] = (args: any) => {
    if (args.isGanttCreated) {
      scrollToToday(ganttInstance);
    }
  };

  const toolbarClick: GanttModel['toolbarClick'] = (args: any) => {
    switch (args.item.text) {
      case 'Add Project':
        setIsAddProjectDialogOpen(true);
        break;
      case 'Mass Project Upload':
        setIsMassProjectUploadDialogOpen(true);
        break;
      case 'Go To Today':
        if (ganttChartInstance) {
          scrollToToday(ganttChartInstance);
        }
        break;
      case 'Zoom in':
        if (ganttChartInstance) {
          scrollToToday(ganttChartInstance);
        }
        break;
      case 'Zoom out':
        if (ganttChartInstance) {
          scrollToToday(ganttChartInstance);
        }
        break;
      case 'Zoom to fit':
        if (ganttChartInstance) {
          scrollToToday(ganttChartInstance);
        }
        break;
      case 'Add Project Roles':
        if (ganttChartInstance && setOpenAddRolesDialog) {
          setOpenAddRolesDialog(true);
        }
        break;
      case 'Edit Taskbar':
        if (ganttChartInstance && setEditTaskbar) {
          setEditTaskbar(true);
        }
        break;
      case 'Exit Edit Taskbar':
        if (ganttChartInstance && setEditTaskbar) {
          setEditTaskbar(false);
        }
        break;
      case 'PDF export':
        if (ganttChartInstance) {
          const exportProperties: PdfExportProperties = {
            fitToWidthSettings: {
              isFitToWidth: true,
            },
            fileName: 'Project_Gantt.pdf',
            pageSize: 'A1',
          };
          ganttChartInstance.pdfExport(exportProperties);
        }
        break;
    }
  };

  // const ganttTimeline = () => {
  //   const today = new Date();
  //   const sDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  //   const eDate = new Date(today.getFullYear() + 1, today.getMonth(), 1);
  //   ganttInstance.updateProjectDates(new Date(sDate), new Date(eDate));
  // };

  const treeColumnIndex = ganttChartInstance?.viewType === 'ProjectView' ? 4 : 1;

  const defaultGanttComponentProps: React.ComponentProps<typeof GanttComponent> = {
    allowSorting: true,
    allowFiltering: true,
    allowPdfExport: ganttChartInstance?.viewType === 'ProjectView',
    timelineSettings: {
      topTier: { unit: 'Year', format: 'yyyy', count: 1 },
      bottomTier: { unit: 'Month', format: 'MMM', count: 1 },
      timelineUnitSize: 99,
      timelineViewMode: 'Year',
    },
    eventMarkers: [
      {
        day: new Date(),
        label: 'Today',
      },
    ],
    selectionSettings: { type: 'Multiple' },
    filterSettings: { type: 'Menu', hierarchyMode: 'Both' },
    searchSettings: { hierarchyMode: 'Both' },
    collapseAllParentTasks: dataFilterProject?.includes(ANY_PROJECT) ?? false,
    allowResizing: true,
    taskbarHeight: 42,
    splitterSettings: { columnIndex: 6 },
    treeColumnIndex: treeColumnIndex,
    disableHtmlEncode: false,
    allowReordering: true,
    showColumnMenu: true,
    height: GANTT_CHART_HEIGHT,
    taskMode: 'Manual',
    editSettings: {
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      allowTaskbarEditing: true,
      showDeleteConfirmDialog: true,
      mode: 'Auto',
      newRowPosition: 'Child',
    },
    dataBound: dataBound,
    projectStartDate: DateTime.now().minus({ month: 1 }).toISO(),
    projectEndDate: DateTime.now().plus({ year: 3 }).toISO(),
  };

  return (
    <Fragment>
      {useMemo(
        () => (
          <>
            <GanttComponent
              {...{
                ...defaultGanttComponentProps,
                ...ganttComponentProps,
                ...logEmitTypeProperties.reduce<React.ComponentProps<typeof GanttComponent>>(
                  (accumulator, emitTypeProperty) => {
                    accumulator[emitTypeProperty] = (...args: any[]) => {
                      console.log(`${emitTypeProperty} called with args:`);
                      args.forEach((arg) => console.log(arg));
                      ganttComponentProps[emitTypeProperty]
                        ? ganttComponentProps[emitTypeProperty](...args)
                        : defaultGanttComponentProps[emitTypeProperty]?.(...args);
                    };
                    return accumulator;
                  },
                  {},
                ),
                toolbarClick: toolbarClick,
                toolbar: toolbar,
                excelQueryCellInfo,
                zoomingLevels: customZoomingLevels,
                // created: ganttTimeline,
              }}
              ref={(gantt: GanttComponent | null | undefined) => (ganttInstance = gantt)}
            >
              <ColumnsDirective>
                {columnDirectivePropsList.map(
                  (columnDirectiveProps: React.ComponentProps<typeof ColumnDirective>, index) => {
                    return <ColumnDirective key={index} {...columnDirectiveProps} />;
                  },
                )}
              </ColumnsDirective>
              <Inject
                services={[
                  Sort,
                  Filter,
                  Toolbar,
                  Edit,
                  Selection,
                  RowDD,
                  Resize,
                  ColumnMenu,
                  Reorder,
                  DayMarkers,
                  VirtualScroll,
                  PdfExport,
                ]}
              />
            </GanttComponent>
            <div>
              <GridComponent>
                <Inject services={[CommandColumn]} />
              </GridComponent>
            </div>
          </>
        ),
        [ganttComponentProps],
      )}
      {/* TODO: we should probably move project logic out of the shared component. */}
      {isProject && (
        <Fragment>
          <AddProjectDialog
            isOpen={isAddProjectDialogOpen}
            setIsOpen={setIsAddProjectDialogOpen}
            getGanttInstance={getGanttInstance}
            selectedEmployeeType={selectedEmployeeType}
            personList={personnelList as Person[]}
            onDataChanged={onDataChanged}
          />
          <>
            {isEnterpriseAdmin && (
              <MassProjectUploadDialog
                isOpen={isMassProjectUploadDialogOpen}
                setIsOpen={setIsMassProjectUploadDialogOpen}
                getGanttInstance={getGanttInstance}
                isOnlyCraft={isOnlyCraft}
              />
            )}
          </>
          <AddProjectRoleDialog
            projectSelection={selectedProject ?? undefined}
            isOpen={openAddRolesDialog ?? false}
            setIsOpen={setOpenAddRolesDialog ?? undefined}
            projectListResult={projectList as Project[]}
            personnelList={personnelList as Person[]}
            selectedEmployeeType={selectedEmployeeType}
            onDataChanged={onDataChanged}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default GanttChart;
